.footer .f-container .footer-col ul {
    list-style: none;
}

.footer .tag {
    color: rgb(240, 202, 255);
    text-shadow: 0px 0px 20px rgba(230, 192, 255, 0.74);
    font-size: 18px;
    letter-spacing: 3px;
    text-align: center;
}

.footer .copyprotect {
    color: white;
    font-size: 16px;
    text-align: center;
    opacity: 0.6;
}

.footer .sep {
    display: flex;
    border: 1px solid rgb(190, 77, 255);
    border-radius: 0.5rem;
    width: 5%;
    margin: 10px auto;
}

.f-container .footer-col {
    width: 25%;
    padding: 0 15px;
}

.f-container .footer-col h4 {
    font-size: 20px;
    font-weight: 500;
    color: white;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 30px;
    position: relative;
}

.f-container .footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 3px;
    box-sizing: border-box;
    border-radius: 25px;
    width: 90px;
    background: rgb(190, 77, 255);
}

.f-container .footer-col ul li:not(:last-child) {
    margin-bottom: 3px;
}

.f-container .footer-col ul li a {
    text-decoration: none;
    color: white;
    text-transform: capitalize;
    display: block;
    transition: all 0.3s ease;
}

.f-container .footer-col ul li a:hover {
    color: rgb(221, 184, 255);
    padding-left: 10px;
}

.footer-col .social-links a {
    display: inline-block;
    margin: 6px;
    text-align: center;
    color: white;
    font-size: 25px;
    transition: all 0.4s ease;
}

.footer-col .social-links a:hover {
    transform: translateY(-5px);
    color: rgb(215, 179, 245);
    text-shadow: 0px 0px 10px rgba(224, 180, 253, 0.555);
}



@media screen and (max-width: 768px) { 
    .f-container .footer-col {
        width: 50%;
        margin-bottom: 30px;
    }

    .footer {
        padding: 30px 70px;
    }
}

@media screen and (max-width: 600px) {
    .f-container .footer-col {
        width: 100%;
    }

    .footer {
        padding: 30px 30px !important;
    }
}