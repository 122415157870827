@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
    transition: all 0.2s ease;
    word-wrap: break-word !important;
}

body {
    background: #090813;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
}

a {
    text-decoration: none !important;
}

::-webkit-scrollbar {
    width: 12px;
    background: #0b0b16;
    border-radius: -6px;

}

::-webkit-scrollbar-thumb {
    width: 12px;
    background-color: rgb(205, 119, 255);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(191, 94, 255);
    text-shadow: 0px 0px 10px rgba(253, 230, 180, 0.555);
}

.content-body {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
}

.wrapper {
    min-width: 100vw;
    min-height: 100vh;
}

.box-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.box {
    align-items: center;
    padding: 1rem;
    margin: 1rem 0;
}

.inner-box {
    align-items: center;
    padding: 1rem;
    margin: 1rem 0;
    max-width: 90vw;
}
.s-inner-box {
    align-items: center;
    padding: 1rem;
    margin: 1rem 0;
    max-width: 80vw;
}
.xs-inner-box {
    align-items: center;
    padding: 1rem;
    margin: 1rem 0;
    max-width: 70vw;
}
.center-box {
    align-items: center;
    padding: 1rem;
    margin: 1rem 0;
    width: 700px;
    background-color: #0c0b1a;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.658) !important;}

.container {
    max-width: 950px;
    width: 100%;
    margin: 1rem 0;
    padding: 15px 0;
    overflow-x: hidden;
}

.container1 {
    max-width: 90vw;
    width: 100%;
    margin: 1rem 0;
    padding: 15px 0;
    overflow-x: hidden;
}

.f-container {
    max-width: 1170px;
    margin: auto;
}

.footer {
    background: #0c0b1a;
    padding: 70px 0;
}

.row {
    display: flex;
    flex-wrap: wrap;
}


@media screen and (max-width: 600px) {

    .box-wrapper {
        width: 100%;
    }

    .container {
        display: none;
    }

    .box {
        align-items: center;
        padding: 0;
        margin: 1rem 0;
    }
    
    .inner-box {
        align-items: center;
        padding: 0 5px;
        margin: 1rem 0;
        max-width: 100vw;
    }
    .s-inner-box {
        align-items: center;
        padding: 0 5px;
        margin: 1rem 0;
        max-width: 100vw;
    }
    .xs-inner-box {
        align-items: center;
        padding: 0 5px;
        margin: 1rem 0;
        max-width: 100vw;
    }

    .center-box {
        width: 85vw;
    }
}