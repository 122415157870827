.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 260px;
    background: #11101d;
    transition: all 0.4s ease;
    z-index: 100;
}

.sidebar.closed {
    width: 78px;
}

.sidebar .logo-details {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
}

.sidebar .logo-details .bx-menu {
    color: #eee;
    display: none;
    pointer-events: none;
}

.sidebar .logo-details .main-icon {
    font-size: 35px;
    color: white;
    min-width: 78px;
    text-align: center;
    line-height: 50px;

}

.sidebar .logo-details .logo-name {
    font-size: 30px;
    color: white;
    pointer-events: none;
    transition: 0.2s ease;
    transition-delay: 0.1s;
}

.sidebar.closed .logo-details .logo-name {
    opacity: 0;
}

.sidebar .nav-links {
    height: 100%;
    padding-top: 30px 0 150px 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

.sidebar .nav-links::-webkit-scrollbar {
    display: none;
}

.sidebar.closed .nav-links {
    overflow: visible;
}

.sidebar .nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
}

.sidebar .nav-links li:hover {
    background: rgb(12, 12, 26);
}

.sidebar .nav-links li .icon-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar.closed .nav-links li .icon-link {
    display: block;
}

.sidebar .nav-links li i {
    color: white;
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
    font-size: 22px;
    cursor: pointer;
    transition: all 0.4s ease
}
.sidebar .nav-links li .icon {
    margin: 0;
    position: absolute;
    top: 50%;
    min-width: 78px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 25px !important;
    cursor: pointer;
    transition: all 0.01s ease;
}

.sidebar .nav-links li.show-menu i.dropdown-arrow {
    transform: rotate(-180deg);
    color: crimson;
}

.sidebar.closed .nav-links i.dropdown-arrow {
    display: none;
}

.sidebar .nav-links li a.current .icon {
    color: crimson;
}

.sidebar .nav-links li:hover .icon {
    color: rgb(182, 93, 255) !important;
}

.sidebar .nav-links li a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.sidebar .nav-links li a .link-name {
    font-size: 18px;
    font-weight: 400;
    color: white;
    white-space: nowrap;
    transition: 0.2s ease;
}

.sidebar.closed .nav-links li a .link-name {
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links .disabled {
    opacity: 0.4;
    pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
    padding: 6px 6px 14px 80px;
    margin-top: -10px;
    background: rgb(12, 12, 26);
    display: none;
}

.sidebar .nav-links li.show-menu .sub-menu {
    display: block;
}

.sidebar .nav-links li .sub-menu a {
    color: white;
    font-size: 16px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.6;
    transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu a:hover {
    opacity: 1;
    color: rgb(215, 166, 255);
}

.sidebar.closed .nav-links li .sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 8px 8px 0;
    transition: 0s;
    display: block;
    opacity: 0;
    pointer-events: none;
}

.sidebar.closed .nav-links li:hover .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}

.sidebar .nav-links .sub-menu .sep {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    width: 60%;
    opacity: 0.4;
}

.sidebar .nav-links li .sub-menu .link-name {
    display: none;
}

.sidebar.closed .nav-links li .sub-menu .link-name {
    display: none;
}

.sidebar.closed .nav-links li .sub-menu .link-name {
    font-size: 18px;
    opacity: 1;
    display: block;
}

.sidebar .nav-links li .sub-menu.blank {
    opacity: 0;
    pointer-events: auto;
    padding: 6px 20px 6px 16px;
    pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
    top: 50%;
    transform: translateY(-50%)
}

.home-section {
    position: fixed;
    height: 60px;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.4s ease;
    z-index: 100 !important;
    pointer-events: none;
}

.sidebar.closed~.home-section {
    left: 78px;
    width: calc(100% - 78px);
}

.home-section .home-content li {
    pointer-events: all;
    margin-top: 10px;
    border-radius: 0 20px 20px 0;
    height: 40px;
    display: flex;
    align-items: center;
    background: #11101d;
    width: 50px;
    transition: all 0.3s ease;
}


.home-section .home-content li .arrow {
    color: white;
    font-size: 25px;
    padding: 0 10px 0 10px;
    transition: all 0.4s ease;
    cursor: pointer;
    width: 100% !important;
}

.home-section .home-content li:hover .arrow {
    color: rgb(166, 111, 255);
}

.sidebar.closed~.home-section .home-content li:hover {
    width: 60px;
}

.sidebar.closed~.home-section .home-content li .arrow {
    transform: rotate(-540deg);
}

a.backtotop {
    text-decoration: none !important;
    position: fixed;
    bottom: -78px;
    left: 0;
    border-radius: 50%;
    width: 78px;
    height: 78px;
    font-size: 2.5rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: 0.3s;
    z-index: 200;
}

a.backtotop:hover {
    color: rgb(182, 93, 255);
}

html:not([data-scroll='0']) a.backtotop {
    bottom: -0px;
}

.usercard .pfp {
    width: 50px;
    height: 50px;
    background: rgba(76, 0, 130, 0.226);
    border-radius: 50%;
}


@media screen and (max-width: 900px) {
    body {
        margin-top: 3rem;
    }

    a.backtotop {
        font-size: 1rem;
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        height: auto;
        width: 100%;
        background: #11101d;
        transition: all 0.4s ease;
        z-index: 100;
    }

    .sidebar.closed {
        width: 100%;
        height: 60px;
    }

    .sidebar.closed .logo-details .logo-name {
        opacity: 1;
    }

    .sidebar .logo-details .logo-name {
        display: none;
    }

    .sidebar .logo-details i {
        display: block;
    }

    .sidebar .nav-links {
        transition: 0.2 ease
    }

    .sidebar.closed .nav-links {
        opacity: 0;
        pointer-events: none;
    }

    .sidebar .logo-details .bx-menu {
        display: block;
        cursor: pointer;
        pointer-events: auto;
        min-width: 78px;
        font-size: 35px;
        margin-right: -5px;
        color: crimson;
        z-index: 500 !important;
    }
    .sidebar.closed .logo-details .bx-menu {
        color: white;
    }

    .home-section {
        position: relative;
        height: 0;
        left: 0;
        width: 0;
        transition: all 0.4s ease;
        display: none;
    }

    .sidebar.closed~.home-section {
        left: 0;
        width: 100vw;
    }
}