.lock {
    pointer-events: none;
    opacity: 0.5;
}

/* SKELETON LOADING */
.skeleton-events {
    pointer-events: none;
}

.skeleton {
    opacity: .1;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
    width: 100%;
    height: .5rem;
    margin-bottom: 5px;
    border-radius: 10px;
}

.skeleton-text-center {
    display: inline-block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 1rem;
    margin-bottom: 5px;
    border-radius: 10px;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

/* SKELETON LOADING */

/* TEXT */
.main-title {
    color: white;
    font-size: 6rem;
    font-weight: 600;
    text-align: center;
    transition: 0.4s ease;
}

.title {
    color: white;
    font-size: 5rem;
    font-weight: 600;
    text-align: center;
    transition: 0.4s ease;
}

.title1 {
    color: white;
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    transition: 0.4s ease;
}

.title2 {
    color: white;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    transition: 0.4s ease;
}

.title3 {
    color: white;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    transition: 0.4s ease;
}

.subtitle {
    color: white;
    font-size: 25px;
    font-weight: 300;
    text-align: center;
    transition: 0.4s ease;
}

.subtitle1 {
    color: white;
    font-size: 25px;
    font-weight: 300;
    text-align: center;
    transition: 0.4s ease;
}

p {
    color: white;
    font-size: 20px;
    font-weight: 300;
    transition: 0.4s ease;
}

.p1 {
    font-size: 17px
}

code {
    color: rgb(255, 127, 127);
    background: #100e22;
    padding: 5px 15px;
    border-radius: 5px;
}

/* TEXT */



/* DEVIDERS */
.s-sep {
    display: flex;
    border: 1px solid rgb(190, 77, 255);
    border-radius: 0.5rem;
    width: 35%;
    margin: 30px auto;
}

.xs-sep {
    display: flex;
    border: 1px solid rgb(190, 77, 255);
    border-radius: 0.5rem;
    width: 25%;
    margin: 20px auto;
}

.xxs-sep {
    display: flex;
    border: 1px solid rgb(190, 77, 255);
    border-radius: 0.5rem;
    width: 15%;
    margin: 10px auto;
}

.xxxs-sep {
    display: flex;
    border: 1px solid rgb(190, 77, 255);
    border-radius: 0.5rem;
    width: 5%;
    margin: 10px auto;
}

.vanish {
    opacity: 0;
    pointer-events: none;
}

.grayscale {
    filter: grayscale(50%);
}

.inline {
    float: left;
}

.inline-2 {
    float: left;
    margin: 1rem;
}

/* DEVIDERS */



/* BUTTONS */
.buttons {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 7px 0;
}

.buttons a,
.buttons button {
    line-height: 2.7rem;
}

.buttons a span,
.buttons button span {
    color: white;
    font-size: 30px;
    font-weight: 300;
}

.buttons a i,
.buttons button i {
    color: white;
    font-size: 25px;
    padding-right: 5px;
}

.buttons a,
.buttons button {
    text-decoration: none;
    margin: 10px;
    text-align: center;
    display: block;
    padding: 10px 20px;
    min-width: 300px;
    transition: all 0.2s !important;
    border-radius: 7px;
    background: #100e22;
    border: none;
}

.buttons a:hover,
.buttons button:hover {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.801);
    transform: translateY(-7px);
}

.buttons a.autosize,
.buttons button.autosize {
    min-width: auto !important;
}

.buttons a.rounded,
.buttons button.rounded {
    border-radius: 50px !important;
}

/* BUTTONS */



/* GUILD MENU LIST */
.guild-list {
    list-style-type: none;
}

.guild-list .guild {
    position: relative;
    margin: 10px auto;
    width: 90%;
    background-color: #0c0b1a;
    padding: 10px 25px;
    border-radius: 20px;
}

.guild-list .guild:hover {
    background-color: #14122b;
    box-shadow: 0px 5px 20px rgba(138, 125, 255, 0.322);
    border: solid rgb(165, 167, 255) 1px;
    transform: translateY(-7px);
}

.guild-list .guild .icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-block;
}

.guild-list .guild .name {
    display: inline-block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: white;
}

/* GUILD MENU LIST */


/* CHANGE LOG */
.changelog {
    padding: 0 5px;
}

.changelog .item {
    color: white;
    background: #131129;
    border-radius: 10px;
    border: solid 2px rgb(43, 41, 94) !important;
}

.changelog .item:hover {
    border-color: rgb(178, 101, 241) !important;
}

.pagination .page-link {
    background: #131129 !important;
    color: white;
    border: solid 2px rgb(43, 41, 94) !important;
}

.pagination .active {
    border-color: rgb(178, 101, 241) !important;
}

.pagination .page-link:hover {
    border-color: rgb(178, 101, 241) !important;
}

/* CHANGE LOG */



/* GRID */
.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    max-width: 85vw;
    margin: 0 auto;
}

.grid .column-2 {
    grid-column: 1 / 3 !important;
}
/* GRID */



/* MAIN FORM */
.main-form {
    margin: 30px 10px;
}

.main-form .text-input,
.main-form .select-input {
    width: 100%;
    border-radius: 10px;
    border: solid 2px rgb(43, 41, 94);
    font-size: 15px;
    font-family: inherit;
    color: white;
    background: none;
    outline: none;
    padding: 6px 5px;
}

.main-form .select-input-boot {
    width: 100% !important;
    border: solid 2px rgb(43, 41, 94);
    border-radius: 10px;
    color: white !important;
    font-size: 15px;
    font-family: inherit;
}

.main-form .select-input-boot .custom-class {
    color: white !important;
}

.main-form .select-input-boot .dropdown-menu {
    background: #131129 !important;
    border-radius: 10px;
    padding: 0 2px;
}

.main-form .select-input-boot .bs-box {
    border-radius: 10px;
    border: solid 2px rgb(43, 41, 94) !important;
    background: #090813 !important;
    margin: 5px 0;
}

.main-form .text-input:focus,
.main-form .select-input:focus {
    border-color: rgb(178, 101, 241) !important;
}

.main-form label {
    font-size: 20px !important;
    color: white;
    font-weight: 300;
    margin-bottom: -1px;
    margin-left: 2px;
}

/* MAIN FORM */



/* CARDS */
.dashboard-card {
    background: rgb(22, 20, 39);
    border-radius: 10px;
    padding: 5px 15px;
    padding-top: 20px;
    margin-bottom: 1rem;
    box-shadow: -10px 5px 20px rgba(0, 0, 0, 0.801);
    min-width: 600px
}
/* CARDS */



@media screen and (max-width: 1286px) {
    .inline-2 {
        float: none !important;
        margin-right: 0;
    }

    /* CARDS */
    .dashboard-card {
        min-width: auto;
    }
    /* CARDS */

    .grid {
        grid-template-columns: 1fr;
        max-width: 100%;
    }
    .grid .column-2 {
        grid-column: 1 / 2 !important;
    }
}

@media screen and (max-width: 768px) {
    .buttons {
        display: grid;
    }
}

@media screen and (max-width: 600px) {

    /* TEXT MOBILE */
    .title {
        margin-top: 3rem;
        font-size: 3rem !important;
    }

    .title1 {
        font-size: 2rem;
    }

    .title2 {
        font-size: 1rem;
    }

    .title3 {
        font-size: 1rem;
    }

    .subtitle {
        font-size: 20px;
    }

    .subtitle1 {
        font-size: 18px;
    }

    p {
        font-size: 17px;
    }

    /* TEXT MOBILE */



    /* GUILD MENU LIST MOBILE */
    .guild-list .guild {
        display: grid !important;
    }

    .guild-list .guild .name {
        display: inline-block;
        margin: 0;
        position: unset;
        top: 0;
        left: 0;
        margin-right: 0;
        transform: translate(0, 0);
        text-align: center;
        font-size: 30px;
        color: white;
    }

    /* GUILD MENU LIST MOBILE */


    
}